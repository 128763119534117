<template>
  <div class="wrap-iterm">
    <div>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          text-align: center;
          line-height: 125%;
          font-size: 24pt;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Arial; color: #212121">Terms of service</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Arial; color: #212121"
          >PLEASE READ THESE TERMS OF SERVICES CAREFULLY BEFORE USING THIS SITE
          AND/OR ANY GAMES. By accessing or using any part of the site and/or
          any games provided by</span
        >
        <span style="font-family: Arial; color: #212121"> {{ title }}</span>

        <span style="font-family: Arial; color: #212121">,you</span>
        <span style="font-family: Arial; color: #212121"
          >signify your assent to these terms of use.</span
        >
        <span style="font-family: Arial; color: #212121"
          >These Terms of Service apply to all users of the site, including
          without limitation users who are browsers, vendors, customers,
          merchants, and/ or contributors of content.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <span style="font-family: Arial; color: #212121"
          >If you do not agree to these Terms and Conditions, please do not use
          the site or the games.</span
        >
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
          background-color: #ffffff;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121"
          >Our store is hosted on</span
        >
        <span style="font-family: Arial; color: #212121"> Xsolla</span>
        <span style="font-family: Arial; color: #212121">.</span>
        <span style="font-family: Arial; color: #212121">They</span>
        <span style="font-family: Arial; color: #212121"
          >provide us with the online e-commerce platform that allows us to sell
          our products and services to you.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <span style="font-family: Arial; color: #212121"
          >Your access to and use of the</span
        >
        <span
          style="font-family: Arial; color: #212121; background-color: #ffff00"
        ></span>
        <span style="font-family: Arial; color: #212121"> {{ title }}</span>

        <span style="font-family: Arial; color: #212121"></span>
        <span style="font-family: Arial; color: #212121">
          game web site (the “Site”), as well as any and all games and/or apps
          licensed to customers by</span
        >
        <span
          style="font-family: Arial; color: #212121; background-color: #ffff00"
        ></span>
        <span style="font-family: Arial; color: #212121"> {{ title }}</span>

        <span style="font-family: Arial; color: #212121">,</span>
        <span style="font-family: Arial; color: #212121"
          >and/or any affiliate or subsidiary of</span
        >
        <span style="font-family: Arial; color: #212121"> {{ title }}</span>

        <span style="font-family: Arial; color: #212121"></span>
        <span style="font-family: Arial; color: #212121">(</span>
        <span style="font-family: Arial; color: #212121"> {{ title }}</span>

        <span style="font-family: Arial; color: #212121">
          and its subsidiaries and affiliates are hereby collectively referred
          to as “</span
        >
        <span style="font-family: Arial; color: #212121"> {{ title }}</span>

        <span style="font-family: Arial; color: #212121"
          >”), via the Site or third party providers (the “Games”), is subject
          to the following terms and conditions (&quot;Terms and
          Conditions&quot;), all terms and conditions of any agreement provided
          by any third party provider, and all applicable laws. In addition, we
          require your express acceptance to these Terms and Conditions and the
          Privacy Policy, the terms of which are incorporated herein by
          reference, when you register to access and use the</span
        >
        <span style="font-family: Arial; color: #212121"> {{ title }}</span>

        <span style="font-family: Arial; color: #212121">
          Friends and services (such as chat rooms, in game chat messaging,
          bulletin boards, etc.) (collectively the “Services). If you do not
          agree to be bound by these Terms and Conditions or the Privacy Policy,
          then you are not permitted to register for access or use of the
          Services and are not to use or access the Services. By accessing and
          browsing the Site, you accept, without limitation or qualification,
          the Terms and Conditions and acknowledge that any other agreements
          between you and</span
        >
        <span style="font-family: Arial; color: #212121"> {{ title }}</span>

        <span style="font-family: Arial; color: #212121">
          are superseded and of no force or effect:</span
        >
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">1.</span>
        <span style="font-family: Arial; color: #212121"
          >By agreeing to these Terms of Service, you represent that you are at
          least the age of majority in your state or province of residence, or
          that you are the age of</span
        >
        <span style="font-family: Arial; color: #212121"
          >majority in your state or province of residence and you have given us
          your consent to allow any of your minor dependents to use this
          site.</span
        >
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">2.</span>
        <span style="font-family: Arial; color: #212121"
          >You may not use our products for any illegal or unauthorized purpose
          nor may you, in the use of the Service, violate any laws in your
          jurisdiction (including but not limited to copyright laws).</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <span style="font-family: Arial; color: #212121"
          >You must not transmit any worms or viruses or any code of a
          destructive nature.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <span style="font-family: Arial; color: #212121"
          >A breach or violation of any of the Terms will result in an immediate
          termination of your Services.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <a name="OLE_LINK1"></a>
        <a name="OLE_LINK2"></a>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">3.</span>
        <span style="font-family: Arial; color: #212121"
          >You understand that your content (not including credit card
          information), may be transferred unencrypted and involve (a)
          transmissions over various networks; and (b) changes to conform and
          adapt to technical requirements of connecting networks or devices.
          Credit card information is always encrypted during transfer over
          networks.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">4.</span>
        <span style="font-family: Arial; color: #212121"
          >You agree not to reproduce, duplicate, copy, sell, resell or exploit
          any portion of the Service, use of the Service, or access to the
          Service or any contact on the website through which the service is
          provided, without express written permission by us.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">5.</span>
        <span style="font-family: Arial; color: #212121"
          >This site may contain certain historical information. Historical
          information, necessarily, is not current and is provided for your
          reference only. We reserve the right to modify the contents of this
          site at any time, but we have no obligation to update any information
          on our site. You agree that it is your responsibility to monitor
          changes to our site.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <span style="font-family: Arial; color: #212121"
          >We are not responsible if information made available on this site is
          not accurate, complete or current. The material on this site is
          provided for general information only and should not be relied upon or
          used as the sole basis for making decisions without consulting
          primary, more accurate, more complete or more timely sources of
          information. Any reliance on the material on this site is at your own
          risk.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <span style="-aw-bookmark-end: OLE_LINK1"></span>
        <span style="-aw-bookmark-end: OLE_LINK2"></span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">6.</span>
        <span style="font-family: Arial; color: #212121"
          >All descriptions of products or product pricing are subject to change
          at</span
        >
        <span style="font-family: Arial; color: #212121">anytime</span>
        <span style="font-family: Arial; color: #212121"
          >without notice, at the sole discretion of us. We reserve the right to
          discontinue</span
        >
        <span style="font-family: Arial; color: #212121"
          >any product at any time. Any offer for any product or service made on
          this site is void where prohibited.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <br />
        <span style="font-family: Arial; color: #212121"
          >We do not warrant that the quality of any products, services,
          information, or other material purchased or obtained by you will meet
          your expectations, or that any errors in the Service will be
          corrected.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">7.</span>
        <span style="font-family: Arial; color: #212121"
          >We reserve the right to refuse any order you place with us. In the
          event that we make a change to or cancel an order, we may attempt to
          notify you by contacting the e</span
        >
        <span style="font-family: Arial; color: #212121">‑</span>
        <span style="font-family: Arial; color: #212121"
          >mail and/or billing address/phone number provided at the time the
          order was made. We reserve the right to limit or prohibit orders that,
          in our sole judgment, appear to be placed by dealers, resellers or
          distributors.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">8.</span>
        <span style="font-family: Arial; color: #212121"
          >You agree to provide current, complete and accurate purchase and
          account information for all purchases made at our store. You agree to
          promptly update your account and other information, including your
          email address and credit card numbers and expiration dates, so that we
          can complete your transactions and contact you as needed.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">9.</span>
        <span style="font-family: Arial; color: #212121"></span>
        <span style="font-family: Arial; color: #212121"
          >Optional Tools and</span
        >
        <span style="font-family: Arial; color: #212121">Third Party</span>
        <span style="font-family: Arial; color: #212121">Links</span>
        <br />
        <span style="font-family: Arial; color: #212121"
          >We may provide you with access to third-party tools over which we
          neither monitor nor have any control nor input.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <br />
        <span style="font-family: Arial; color: #212121"
          >You acknowledge and agree that we provide access to such</span
        >
        <span style="font-family: Arial; color: #212121">tools ”as</span>
        <span style="font-family: Arial; color: #212121"
          >is” and “as available” without any warranties, representations or
          conditions of any kind and without any endorsement. We shall have no
          liability whatsoever arising from or relating to your use of optional
          third-party tools.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <br />
        <span style="font-family: Arial; color: #212121"
          >Any use by you of optional tools offered through the site is entirely
          at your own risk and discretion and you should ensure that you are
          familiar with and approve of the terms on which tools are provided by
          the relevant third-party provider(s).</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <br />
        <span style="font-family: Arial; color: #212121"
          >We may also, in the future, offer new services and/or features
          through the website (including, the release of new tools and
          resources). Such new features and/or services shall also be subject to
          these Terms of Service.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <br />
        <span style="font-family: Arial; color: #212121"
          >Certain content, products and services available via our Service may
          include materials from third-parties.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <br />
        <span style="font-family: Arial; color: #212121"
          >Third-party links on this site may direct you to third-party websites
          that are not</span
        >
        <span style="font-family: Arial; color: #212121"
          >affiliated with us. We are not responsible for examining or
          evaluating the content or accuracy and we do not warrant and will not
          have any liability or responsibility for any third-party materials or
          websites, or for any other materials, products, or services of
          third-parties.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <br />
        <span style="font-family: Arial; color: #212121"
          >We are not liable for any harm or damages related to the purchase or
          use of goods, services, resources, content, or any other transactions
          made in connection with any third-party websites. Please review
          carefully the third-party's policies and practices and make sure you
          understand them before you engage in any transaction. Complaints,
          claims, concerns, or questions regarding third-party products should
          be directed to the third-party.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">10.</span>
        <span style="font-family: Arial; color: #212121"
          >Occasionally there may be information on our site or in the Service
          that contains typographical errors, inaccuracies or omissions that may
          relate to product descriptions, pricing, promotions, offers, product
          shipping charges, transit times and availability. We reserve the right
          to correct any errors, inaccuracies or omissions, and to change or
          update information or cancel orders if any information in the Service
          or on any related website is inaccurate at any time without prior
          notice (including after you have submitted your order).</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <span style="font-family: Arial; color: #212121"
          >We undertake no obligation to update, amend or clarify information in
          the Service or on any related website, including without limitation,
          pricing information, except as required by law. No specified update or
          refresh date applied in the Service or on any related website, should
          be taken to indicate that all information in the Service or on any
          related website has been modified or updated.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">11.</span>
        <span style="font-family: Arial; color: #212121"
          >In addition to other prohibitions as set forth in the Terms of
          Service, you are prohibited from using the site or its content: (a)
          for any unlawful purpose; (b) to solicit others to perform or
          participate in any unlawful acts; (c) to violate any international,
          federal, provincial or state regulations, rules, laws, or local
          ordinances; (d) to infringe upon or violate our intellectual property
          rights or the intellectual property rights of others; (e) to harass,
          abuse, insult, harm, defame, slander, disparage, intimidate, or
          discriminate based on gender, sexual orientation, religion, ethnicity,
          race, age, national origin, or disability; (f) to submit false or
          misleading information; (g) to upload or transmit viruses or any other
          type of malicious code that will or may be used in any way that will
          affect the functionality or operation of the Service or of any related
          website, other websites,</span
        >
        <span style="font-family: Arial; color: #212121"
          >or the Internet; (h) to collect or track the personal information of
          others; (</span
        >
        <span style="font-family: Arial; color: #212121">i</span>
        <span style="font-family: Arial; color: #212121"
          >) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for
          any obscene or immoral purpose; or (k) to interfere with or circumvent
          the security features of the Service or any related website, other
          websites, or the Internet. We reserve the right to terminate your use
          of the Service or any related website for violating any of the
          prohibited uses.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">12.</span>
        <span style="font-family: Arial; color: #212121">D</span>
        <span style="font-family: Arial; color: #212121">isclaimer of</span>
        <span style="font-family: Arial; color: #212121">W</span>
        <span style="font-family: Arial; color: #212121">arranties and</span>
        <span style="font-family: Arial; color: #212121">L</span>
        <span style="font-family: Arial; color: #212121">imitation of</span>
        <span style="font-family: Arial; color: #212121">L</span>
        <span style="font-family: Arial; color: #212121">iability</span>
        <br />
        <span style="font-family: Arial; color: #212121"
          >We do not guarantee, represent or warrant that your use of our
          service will be uninterrupted, timely, secure or error-free.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <br />
        <span style="font-family: Arial; color: #212121"
          >We do not warrant that the results that may be obtained from the use
          of the service will be accurate or reliable.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <br />
        <span style="font-family: Arial; color: #212121"
          >You agree that from time to time we may remove the service for
          indefinite periods of time or cancel the service at any time, without
          notice to you.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <br />
        <span style="font-family: Arial; color: #212121"
          >You expressly agree that your use of, or inability to use, the
          service is at your sole risk. The service and all products and
          services delivered to you through the service are provided 'as is' and
          'as available' for your use, without any representation, warranties or
          conditions of any kind, either express or implied, including all
          implied warranties or conditions of merchantability, merchantable
          quality, fitness for a particular purpose, durability, title, and
          non-infringement.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <br />
        <span style="font-family: Arial; color: #212121">In no case shall</span>
        <span style="font-family: Arial; color: #212121"></span>
        <span style="font-family: Arial; color: #212121"> {{ title }}</span>

        <span style="font-family: Arial; color: #212121">,</span>
        <span style="font-family: Arial; color: #212121"></span>
        <span style="font-family: Arial; color: #212121"
          >our directors, officers, employees, affiliates, agents, contractors,
          interns, suppliers, service providers or licensors be liable for any
          injury, loss, claim, or any direct, indirect, incidental, punitive,
          special, or consequential damages of any kind, including, without
          limitation lost profits, lost revenue, lost savings, loss of data,
          replacement costs, or any similar damages, whether based in contract,
          tort (including negligence), strict liability or otherwise, arising
          from your use of any of the service or any products procured using the
          service, or for any other claim related in any way to your use of the
          service or any product, including, but not limited to, any errors or
          omissions in any content, or any loss or damage of any kind incurred
          as a result of the use of the service or any content (or product)
          posted, transmitted, or otherwise made available via the service, even
          if advised of their possibility. Because some states or jurisdictions
          do not allow the exclusion or the limitation of liability for
          consequential or incidental damages, in such states or jurisdictions,
          our liability shall be limited to the maximum extent permitted by
          law.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">13.</span>
        <span style="font-family: Arial; color: #212121"
          >You agree to indemnify, defend and hold harmless</span
        >
        <span style="font-family: Arial; color: #212121"> {{ title }}</span>

        <span style="font-family: Arial; color: #212121">
          and our parent, subsidiaries, affiliates, partners, officers,
          directors, agents, contractors, licensors, service providers,
          subcontractors, suppliers, interns and employees, harmless from any
          claim or demand, including reasonable attorneys’ fees, made by any
          third-party due to or arising out of your breach of these Terms of
          Service or the documents they incorporate by reference, or your
          violation of any law or the rights of a third-party.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">14.</span>
        <span style="font-family: Arial; color: #212121"
          >In the event that any provision of these Terms of Service is
          determined to be unlawful, void or unenforceable, such provision shall
          nonetheless be enforceable to the fullest extent permitted by
          applicable law, and the unenforceable portion shall be deemed to be
          severed from these Terms of Service, such determination shall not
          affect the validity and enforceability of any other remaining
          provisions.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">15.</span>
        <span style="font-family: Arial; color: #212121"
          >These Terms of Service are effective unless and until terminated by
          either you or us. You may terminate these Terms of Service at any time
          by notifying us that you no longer wish to use our Services, or when
          you cease using our site.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <br />
        <span style="font-family: Arial; color: #212121"
          >If in our sole judgment you fail, or we suspect that you have failed,
          to comply with any term or provision of these Terms of Service, we
          also may terminate this agreement at any time without notice and you
          will remain liable for all amounts due up to and including the date of
          termination; and/or accordingly may deny you access to our Services
          (or any part thereof).</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">16.</span>
        <span style="font-family: Arial; color: #212121"
          >The failure of us to exercise or enforce any right or provision of
          these Terms of Service shall not constitute a waiver of such right or
          provision.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <br />
        <span style="font-family: Arial; color: #212121"
          >These Terms of Service and any policies or operating rules posted by
          us on this site or in respect to The Service constitutes the entire
          agreement and understanding between you and us and govern your use of
          the Service, superseding any prior or contemporaneous agreements,
          communications and proposals, whether oral or written, between you and
          us (including, but not limited to, any prior versions of the Terms of
          Service).</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <br />
        <span style="font-family: Arial; color: #212121"
          >Any ambiguities in the interpretation of these Terms of Service shall
          not be construed against the drafting party.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">17.</span>
        <span style="font-family: Arial; color: #212121"
          >These Terms of Service and any separate agreements whereby we provide
          you Services shall be governed by and construed in accordance with the
          laws of</span
        >
        <span style="font-family: Arial; color: #212121">U</span>
        <span style="font-family: Arial; color: #212121">nite States of</span>
        <span style="font-family: Arial; color: #212121">America.</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">18.</span>
        <span style="font-family: Arial; color: #212121"
          >You can review the most current version of the Terms of Service at
          any time at this page.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <span style="font-family: Arial; color: #212121"
          >We reserve the right at any time to modify or discontinue the Service
          (or any part or content thereof) without notice at any time.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
        <span style="font-family: Arial; color: #212121"
          >It is your responsibility to check our website periodically for
          changes. Your continued use of or access to our website or the Service
          following the posting of any changes to these Terms of Service
          constitutes acceptance of those changes.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <br />
        <span style="font-family: Arial; color: #212121">19.</span>
        <span style="font-family: Arial; color: #212121"
          >Your submission of personal information through the store is governed
          by our Privacy Policy. To view our Privacy Policy.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
        "
      >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Arial; color: #212121">20.</span>
        <span style="font-family: Arial; color: #212121"
          >The headings used in this agreement are included for convenience only
          and will not limit or otherwise affect these Terms.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Arial; color: #212121">21.</span>
        <span style="font-family: Arial; color: #212121"
          >We reserve the right to refuse service to anyone for any reason at
          any time.</span
        >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Arial; color: #212121">&nbsp;</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 11.5pt;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Arial; color: #212121">22.</span>
        <span style="font-family: Arial; color: #212121"
          >Questions about the Terms of Service should be sent to us at</span
        >
        <span style="font-family: Arial; color: #212121">:</span>
        <a href="mailto:cashhoardslots@gmail.com" style="text-decoration: none">
          <span
            style="
              font-family: Arial;
              text-decoration: underline;
              color: #0000ff;
            "
            >{{ email }}</span
          ></a
        >
        <span style="font-family: Arial; color: #212121">.</span>
      </p>
      <p
        style="
          margin-top: 7.8pt;
          margin-bottom: 7.8pt;
          line-height: 125%;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Arial; color: #ffffff">&nbsp;</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {
      title: localStorage.getItem("documentTitle"),
      email: localStorage.getItem("email"),
    };
  },
};
</script>
<style lang="scss" scoped>
.wrap-iterm {
  width: 100%;
  padding: 0 20px;
  background-color: #fff;
  text-align: left;
}
</style>
